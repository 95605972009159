export default function usePageNavigation(target: Ref<HTMLElement | null>, view: string) {
  const navigationStore = useNavigationStore();
  useIntersectionObserver(
    target,
    ([entry]) => {
      if (entry?.isIntersecting) {
        navigationStore.view = view;
      }
    },
    {
      rootMargin: "-1px -1px -1px -1px"
    }
  );
}
