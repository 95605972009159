<script setup lang="ts">
import type { PrimitiveProps } from "radix-vue";
import { getCurrentInstance, useSlots, type HTMLAttributes } from "vue";

import { Icon } from "@iconify/vue";

import { type ButtonVariants, buttonVariants } from "./variants";

const slots = useSlots();

const thisInstance = getCurrentInstance();
const hasConfirm = computed(() => !!thisInstance?.vnode?.props?.onConfirm);

interface ButtonProps extends PrimitiveProps {
  variant?: ButtonVariants["variant"];
  size?: ButtonVariants["size"];
  class?: HTMLAttributes["class"];
  to?: string;
  icon?: string;
  loading?: boolean;
  text?: string;
  tooltip?: string;
  side?: "top" | "right" | "bottom" | "left";
  autofocus?: boolean;
  groupEvent?: boolean;
  confirmLayout?: "row" | "column";
}
defineOptions({
  inheritAttrs: false
});

const props = withDefaults(defineProps<ButtonProps>(), {
  as: "button",
  variant: "default",
  size: "default",
  text: undefined,
  icon: undefined,
  class: undefined,
  to: undefined,
  loading: false,
  tooltip: undefined,
  side: undefined,
  autofocus: false,
  groupEvent: false,
  confirmLayout: "row"
});

const emit = defineEmits<{
  (event: "click", payload: MouseEvent): void;
  (event: "confirm" | "cancel"): void;
}>();

const buttontRef = ref<HTMLButtonElement | null>(null);
defineExpose({
  buttontRef
});

const showConfirm = ref(false);

async function onClick(event: MouseEvent) {
  if (props.to) {
    const external = props.to.startsWith("http");
    await navigateTo(props.to, {
      external,
      ...(external && {
        open: {
          target: "_blank",
          windowFeatures: {
            noreferrer: true,
            noopener: true
          }
        }
      })
    });
  } else {
    if (hasConfirm.value) {
      showConfirm.value = true;
    }
    emit("click", event);
  }
}

function onConfirm() {
  emit("confirm");
  showConfirm.value = false;
}

function onCancel() {
  emit("cancel");
  showConfirm.value = false;
}

onMounted(() => {
  if (props.autofocus) {
    buttontRef.value?.focus();
  }
});
</script>

<template>
  <BasicTooltip v-if="!showConfirm" :disabled="!tooltip">
    <template #trigger>
      <Primitive :asChild>
        <button
          ref="buttontRef"
          :class="
            cn(buttonVariants({ variant, size }), props.class, {
              'has-group': props.groupEvent,
              'has-text': text || slots.default,
              'cursor-not-allowed pointer-events-none': loading
            })
          "
          v-bind="$attrs"
          @click.stop="onClick"
        >
          <Icon
            v-if="icon"
            :icon="loading ? ICONS.loader : icon"
            :class="
              cn('-mx-1 shrink-0', {
                'animate-spin': loading
              })
            "
          />
          <span v-if="text">{{ $t(text) }}</span>
          <slot />
        </button>
      </Primitive>
    </template>
    <template v-if="tooltip" #content>
      {{ $t(tooltip) }}
    </template>
  </BasicTooltip>
  <Confirm
    v-else
    :size
    :class="$props.class"
    autofocus
    :confirmLayout
    @confirm="onConfirm"
    @cancel="onCancel"
  />
</template>
