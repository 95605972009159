import type { NavigationLink } from "~/types";

export default function useActiveLinks() {
  const links = useNavigationLinks();
  const route = useRoute();
  return computed(
    () =>
      links?.value.reduce((acc, link: NavigationLink) => {
        if (isLinkActive(route.path, link.to)) {
          acc.push(link);
          if (link.sublinks) {
            link.sublinks.forEach((sublink) => {
              if (isLinkActive(route.path, sublink.to)) {
                acc.push(sublink);
              }
            });
          }
        }
        return acc;
      }, [] as NavigationLink[]) || []
  );
}
