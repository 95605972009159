
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexOiyZCtV3aRMeta } from "/src/src/pages/admin/settings/index.vue?macro=true";
import { default as _91id_937LvkoSqsxwMeta } from "/src/src/pages/admin/users/[id].vue?macro=true";
import { default as indexzzT2gb703RMeta } from "/src/src/pages/admin/users/index.vue?macro=true";
import { default as authentication_45settingskhkCAIvqNDMeta } from "/src/src/pages/authentication-settings.vue?macro=true";
import { default as _91id_93TQ74yHXcqWMeta } from "/src/src/pages/claims/[filter]/[id].vue?macro=true";
import { default as indexpSnXOuvFhDMeta } from "/src/src/pages/claims/[filter]/index.vue?macro=true";
import { default as channelsjJC4CjUDm8Meta } from "/src/src/pages/forum/channels.vue?macro=true";
import { default as dmstjWp32uGrgMeta } from "/src/src/pages/forum/dms.vue?macro=true";
import { default as _91id_93l5sXeepxKWMeta } from "/src/src/pages/handbook/[id].vue?macro=true";
import { default as indexvxOftl3m6DMeta } from "/src/src/pages/handbook/index.vue?macro=true";
import { default as indexHKxAhO6PSdMeta } from "/src/src/pages/index.vue?macro=true";
import { default as loginCxV17qPhEjMeta } from "/src/src/pages/login.vue?macro=true";
import { default as indexulvIlxeqXWMeta } from "/src/src/pages/profile/index.vue?macro=true";
import { default as recoveryTNCH2mGFEQMeta } from "/src/src/pages/recovery.vue?macro=true";
import { default as registrationsIIGFSz4XfMeta } from "/src/src/pages/registration.vue?macro=true";
import { default as _91id_93NInKcQOawbMeta } from "/src/src/pages/submissions/[filter]/[id].vue?macro=true";
import { default as indexsT7pncfdlWMeta } from "/src/src/pages/submissions/[filter]/index.vue?macro=true";
import { default as verification5ieRMtHZDUMeta } from "/src/src/pages/verification.vue?macro=true";
import { default as component_45stubgxFpV4faLuMeta } from "/src/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubgxFpV4faLu } from "/src/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-settings",
    path: "/admin/settings",
    meta: indexOiyZCtV3aRMeta || {},
    component: () => import("/src/src/pages/admin/settings/index.vue")
  },
  {
    name: "admin-users-id",
    path: "/admin/users/:id()",
    meta: _91id_937LvkoSqsxwMeta || {},
    component: () => import("/src/src/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: indexzzT2gb703RMeta || {},
    component: () => import("/src/src/pages/admin/users/index.vue")
  },
  {
    name: "authentication-settings",
    path: "/authentication-settings",
    meta: authentication_45settingskhkCAIvqNDMeta || {},
    component: () => import("/src/src/pages/authentication-settings.vue")
  },
  {
    name: "claim",
    path: "/claims/:filter()/:id()",
    meta: _91id_93TQ74yHXcqWMeta || {},
    component: () => import("/src/src/pages/claims/[filter]/[id].vue")
  },
  {
    name: "claims",
    path: "/claims/:filter()",
    meta: indexpSnXOuvFhDMeta || {},
    component: () => import("/src/src/pages/claims/[filter]/index.vue")
  },
  {
    name: "forum",
    path: "/forum/channels",
    meta: channelsjJC4CjUDm8Meta || {},
    component: () => import("/src/src/pages/forum/channels.vue")
  },
  {
    name: "forum-dms",
    path: "/forum/dms",
    meta: dmstjWp32uGrgMeta || {},
    component: () => import("/src/src/pages/forum/dms.vue")
  },
  {
    name: "handbook-page",
    path: "/handbook/:id()",
    meta: _91id_93l5sXeepxKWMeta || {},
    component: () => import("/src/src/pages/handbook/[id].vue")
  },
  {
    name: "handbook",
    path: "/handbook",
    meta: indexvxOftl3m6DMeta || {},
    component: () => import("/src/src/pages/handbook/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexHKxAhO6PSdMeta || {},
    component: () => import("/src/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginCxV17qPhEjMeta || {},
    component: () => import("/src/src/pages/login.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexulvIlxeqXWMeta || {},
    component: () => import("/src/src/pages/profile/index.vue")
  },
  {
    name: "recovery",
    path: "/recovery",
    meta: recoveryTNCH2mGFEQMeta || {},
    component: () => import("/src/src/pages/recovery.vue")
  },
  {
    name: "registration",
    path: "/registration",
    meta: registrationsIIGFSz4XfMeta || {},
    component: () => import("/src/src/pages/registration.vue")
  },
  {
    name: "submission",
    path: "/submissions/:filter()/:id()",
    meta: _91id_93NInKcQOawbMeta || {},
    component: () => import("/src/src/pages/submissions/[filter]/[id].vue")
  },
  {
    name: "submissions",
    path: "/submissions/:filter()",
    meta: indexsT7pncfdlWMeta || {},
    component: () => import("/src/src/pages/submissions/[filter]/index.vue")
  },
  {
    name: "verification",
    path: "/verification",
    meta: verification5ieRMtHZDUMeta || {},
    component: () => import("/src/src/pages/verification.vue")
  },
  {
    name: component_45stubgxFpV4faLuMeta?.name,
    path: "/forum",
    component: component_45stubgxFpV4faLu
  },
  {
    name: component_45stubgxFpV4faLuMeta?.name,
    path: "/submissions",
    component: component_45stubgxFpV4faLu
  },
  {
    name: component_45stubgxFpV4faLuMeta?.name,
    path: "/claims",
    component: component_45stubgxFpV4faLu
  },
  {
    name: component_45stubgxFpV4faLuMeta?.name,
    path: "/users",
    component: component_45stubgxFpV4faLu
  }
]