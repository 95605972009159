import { defineStore } from "pinia";

export const useUserProfileStore = defineStore("userProfile", {
  state: () => ({
    username: ""
  }),
  actions: {
    removeUsername() {
      this.username = "";
    },
    setUser(username: string) {
      this.username = username;
    }
  }
});
