// plugins/matrix.js
import { defineNuxtPlugin } from "#app";
import * as sdk from "matrix-js-sdk";
import { EventType, MsgType, RelationType } from "matrix-js-sdk";
import { logger } from "matrix-js-sdk/lib/logger";

import type { Session } from "@ory/client";

import type { Comment, Participant } from "~/types";

logger.disableAll();

// Polyfill for the global object
if (typeof global === "undefined") {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).global = window;
}
let matrixClient: sdk.MatrixClient;

logger.disableAll();
export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();

  // Initialize the Matrix client
  if (!matrixClient) {
    matrixClient = sdk.createClient({
      baseUrl: config.public.chatBaseUrl // Replace with your Matrix server URL
    });
  }

  // start the client to setup the connection to the server

  const { $ory, $logger } = useNuxtApp();
  const EVENT_PAGE_SIZE = 50;
  // Login part
  const { session } = $ory;
  const matrixSession = ref<sdk.LoginResponse | undefined>(undefined);
  const initialSynced = ref(false);
  const chatStore = useChatStore();

  async function onUserLogin() {
    try {
      const { token } = await getJWToken(config.public.apiBase);
      const response = await matrixClient.login("org.matrix.login.jwt", {
        token
      });
      matrixSession.value = response;
      matrixClient.startClient({ initialSyncLimit: EVENT_PAGE_SIZE });

      // Listen for the sync event
      matrixClient.on(sdk.ClientEvent.Sync, (state: sdk.SyncState) => {
        if (state === "PREPARED") {
          acceptOpenInvitesToSpaces();
          initialSynced.value = true;
        }
      });
    } catch (error) {
      $logger.error("chat: Login failed", error);
    }
  }
  function waitForNextSync() {
    return new Promise((resolve) => {
      const syncListener = (state: sdk.SyncState) => {
        if (state === sdk.SyncState.Syncing) {
          matrixClient.removeListener(sdk.ClientEvent.Sync, syncListener);
          resolve(true);
        }
      };
      setTimeout(() => {
        matrixClient.removeListener(sdk.ClientEvent.Sync, syncListener);
        resolve(true);
      }, 10000);

      matrixClient.on(sdk.ClientEvent.Sync, syncListener);
    });
  }

  function waitForInitalSync() {
    return new Promise((resolve) => {
      if (initialSynced.value) {
        resolve(true);
        return;
      }

      const syncListener = (state: sdk.SyncState) => {
        if (state === "PREPARED") {
          matrixClient.removeListener(sdk.ClientEvent.Sync, syncListener);
          resolve(true);
        }
      };

      matrixClient.on(sdk.ClientEvent.Sync, syncListener);
    });
  }

  function userLogout() {
    $logger.debug("chat: User logged out of the matrix");
  }

  watch(session, (newSession: Session) => {
    if (newSession !== null) {
      onUserLogin();
    } else {
      userLogout();
    }
  });

  // Listen for membership changes and accept invites to spaces

  function acceptOpenInvitesToSpaces() {
    const rooms = matrixClient.getRooms();
    const invites = rooms.filter((room) => {
      const membership = room.getMyMembership();
      const isSpace = room.getType() === "m.space"; // Check if the room is a space
      return membership === "invite" && isSpace;
    });

    invites.forEach((invite) => {
      // Automatically join the space
      matrixClient
        .joinRoom(invite.roomId)
        .then(() => {
          $logger.debug(`Joined space: ${invite.roomId}`);
        })
        .catch((error) => {
          console.error(`Failed to join space: ${invite.roomId}`, error);
        });
    });

    if (invites.length === 0) {
      $logger.debug("No open invites to spaces.");
    }
  }
  matrixClient.on(sdk.RoomMemberEvent.Membership, (event, member) => {
    if (member.membership === "invite" && member.userId === matrixClient.getUserId()) {
      const roomId = member.roomId;
      const room = matrixClient.getRoom(roomId);
      const isSpace = room?.getType() === "m.space"; // Check if the room is a space

      if (isSpace) {
        // Automatically join the space
        matrixClient
          .joinRoom(roomId)
          .then(() => {
            $logger.debug(`Joined space: ${roomId}`);
          })
          .catch((error) => {
            console.error(`Failed to join space: ${roomId}`, error);
          });
      } else {
        // TODO: Notify user about the invite to a non-space room
        $logger.debug(`Invite to non-space room: ${roomId}`);
      }
    }
  });

  // rooom Event Handline
  const { addEvent, getEvent, updateEventId, addComment, getComment, reset } = useEventMap();
  const isLoadingPage = ref(true);
  const isLoadingRoom = ref(true);
  const toastsStore = useToastsStore();
  const roomName = ref<string | null>(null);
  const threadId = ref<string | null>(null);
  const roomAlias = computed(() => nameToChannel(roomName.value, config.public.chatBaseUrl));
  const error = ref<string | null>(null);
  const comments = ref<Comment[]>([]);
  const participants = ref<Participant[]>([]);
  const currentRoom = ref<sdk.Room | null>(null);
  const threadComments = ref<Comment[]>([]);
  const threadRootComment = ref<Comment | null>(null);
  const threads = ref<Record<string, Comment[]>>({});
  const canPaginate = ref(false);

  function handleMessageEvent(event: sdk.MatrixEvent) {
    if (event.getContent().body) {
      if (event.getRelation()?.event_id && event.getRelation()?.rel_type === RelationType.Replace) {
        const comment = getComment(event.getRelation()!.event_id!);
        if (!comment) {
          return;
        }
        comment.updatedAt = event.getDate()?.toISOString() ?? "";
        comment.content = upgradePlainTextToEditorContent(event.getContent()["m.new_content"].body);

        return;
      }
      const comment = messageEventToComment(event, threads.value[event.getId() as string]?.length ?? 0);
      addComment(comment);
      if (event.isRelation("m.thread")) {
        const parentEventId = event.getRelation()?.event_id;
        if (parentEventId) {
          const rootComment = getComment(parentEventId);
          if (rootComment) {
            rootComment.threadAggregate!.aggregate!.count += 1;
          }
          if (threads.value[parentEventId]) {
            threads.value[parentEventId].push(comment);
          } else {
            threads.value[parentEventId] = [comment];
          }
        }
        return;
      } else {
        comments.value.push(comment);
      }
    }
  }

  function handleReactionEvent(event: Partial<sdk.IEvent>) {
    const commentId = event.content?.["m.relates_to"]?.event_id;
    const comment = getComment(commentId!);
    if (comment && event.event_id) {
      const reaction = messageEventToReaction(event);
      comment.userReactions.push(reaction);
    }
  }

  function handleMemberEvent(event: sdk.MatrixEvent) {
    const username = extractUsernameFromAlias(event.event.sender);
    if (!username) {
      $logger.error("chat: handleMemberEvent: username not found", event.event.sender);
      return;
    }
    // console.log("handleMemberEvent", username);
    // console.log(toPlainClone(participants.value));

    participants.value.push({
      id: username,
      username: username
    });
  }

  function handleRedactionEvent(event: sdk.MatrixEvent & { event: { redacts: string } }) {
    const redactedEvent = getEvent(event.event.redacts);
    // matrixClient.getEvent(event.event.redacts);

    if (!redactedEvent) {
      //event seems not to be in the event map yet
      return;
    }
    if (redactedEvent.getType() === "m.room.message") {
      const comment = getComment(redactedEvent.getId()!);
      if (comment) {
        comment.deleted = event.getContent().reason === CHAT_REDACTED_REASONS.DELETED_BY_USER;
        comment.blocked = event.getContent().reason === CHAT_REDACTED_REASONS.BLOCKED_BY_MODERATOR;
        comment.content = upgradePlainTextToEditorContent(event.getContent().reason);
      } else {
        const comment = {
          id: redactedEvent.getId(),
          createdByUser: {
            id: redactedEvent.getSender(),
            username: extractUsernameFromAlias(redactedEvent.getSender()) ?? ""
          },
          createdAt: event.getDate()?.toISOString() ?? "",
          // updatedAt: event.getTimestamp(),
          content: upgradePlainTextToEditorContent(event.getContent().reason ?? ""), //upgradePlainTextToEditorContent(messageBody ? messageBody : "deleted"),
          deleted: event.getContent()?.reason === CHAT_REDACTED_REASONS.DELETED_BY_USER,
          blocked: event.getContent()?.reason === CHAT_REDACTED_REASONS.BLOCKED_BY_MODERATOR,
          userReactions: [],
          threadAggregate: {
            aggregate: {
              count: 0
            }
          }
        };
        addComment(comment);
        const parentEventId = event.getRelation()?.event_id;
        if (parentEventId) {
          const rootComment = getComment(parentEventId);
          if (rootComment) {
            rootComment.threadAggregate!.aggregate!.count += 1;
          }
          if (threads.value[parentEventId]) {
            threads.value[parentEventId].push(comment);
          } else {
            threads.value[parentEventId] = [comment];
          }
        } else {
          comments.value.push(comment);
        }
      }

      return;
    }

    if (redactedEvent.getType() === "m.reaction" && redactedEvent.getRelation()) {
      const comment = getComment(redactedEvent.getRelation()!.event_id!);
      comment.userReactions = comment?.userReactions.filter(
        (reaction: { id: string }) => reaction.id !== redactedEvent.getId()
      );

      return;
    }
  }
  function handleRoomEvent(event: sdk.MatrixEvent) {
    addEvent(event);
    if (event.getType() === "m.room.message") {
      handleMessageEvent(event);
      return;
    }
    if (event.getType() === "m.reaction") {
      handleReactionEvent(event.event);
      return;
    }
    if (event.getType() === "m.room.redaction") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handleRedactionEvent(event as any);
      return;
    }
    if (event.getType() === "m.room.member") {
      handleMemberEvent(event);
      return;
    }
  }

  // local temp Id updating on event resolving
  matrixClient.on(sdk.ClientEvent.Room, (room) => {
    room.on(
      sdk.RoomEvent.LocalEchoUpdated,
      (event: sdk.MatrixEvent, room: sdk.Room, oldEventId?: string, _?: null | string) => {
        if (
          oldEventId &&
          event.event.event_id &&
          event.event.event_id !== oldEventId &&
          event.status === sdk.EventStatus.SENT
        ) {
          updateEventId(event.event.event_id, oldEventId);
        }
      }
    );

    room.on(sdk.RoomEvent.Timeline, (event, room, toStartOfTimeline) => {
      if (toStartOfTimeline) return; // Ignore events from the start of the timeline
      if (room?.name !== currentRoom.value?.name) return;
      handleRoomEvent(event);
    });
  });

  // Listen for typing changes
  matrixClient.on(sdk.RoomMemberEvent.Typing, function (event, member) {
    if (member.typing) {
      $logger.debug(member.name + " is typing...");
    } else {
      $logger.debug(member.name + " stopped typing.");
    }
  });

  // Handline Room Changes
  watch(roomName, async (newRoomName) => {
    if (newRoomName) {
      try {
        isLoadingRoom.value = true;
        isLoadingPage.value = true;
        participants.value = [];
        comments.value = [];
        threadComments.value = [];
        threadRootComment.value = null;
        reset();
        threads.value = {};
        // Check if room is already joined if not join the room

        const { room_id: roomId } = await withRetry(
          () => matrixClient.getRoomIdForAlias(roomAlias.value!),
          5,
          300
        );
        // console.log("roomId", roomId);
        if (!roomId) {
          toastsStore.add({
            title: "Room not found",
            description: `Room with alias ${roomAlias.value} not found`,
            type: "error"
          });
          return;
        }
        let room = matrixClient.getRoom(roomId!);
        // console.log("room", room);

        if (!room) {
          // console.log("joining room");

          await withRetry(() => matrixClient.joinRoom(roomId!, { syncRoom: false }), 10, 300);
          // console.log("waiting for next sync");
          await waitForNextSync();
          room = matrixClient.getRoom(roomId!);
        }
        if (!room) {
          throw new Error(`Failed to join Room ${roomAliasToRoomName(roomAlias.value!)}`);
        }

        const timeline = room.getLiveTimeline();
        const events = timeline.getEvents();

        canPaginate.value = await matrixClient.paginateEventTimeline(timeline, {
          backwards: true,
          limit: EVENT_PAGE_SIZE
        });

        events.forEach((event) => handleRoomEvent(event));
        participants.value = room
          .getMembers()
          .filter((member) => !member.name.includes("system"))
          .map(channelMemberToParticipant);
        currentRoom.value = room;
        threadId.value = chatStore.getThreadIdByChannelId(currentRoom.value.name ?? "") ?? null;
        if (threadId.value) {
          // after loading content update thread contents
          switchToThread(threadId.value, true);
        }
      } catch (joinError) {
        isLoadingPage.value = true;
        isLoadingRoom.value = true;
        error.value = joinError as string;
        toastsStore.add({
          title: "Error during joining Room",
          description: joinError as string,
          type: "error"
        });
        return;
      }
    } else {
      isLoadingRoom.value = true;
      isLoadingPage.value = true;
      threadId.value = null;
      threadComments.value = [];
      threadRootComment.value = null;
      comments.value = [];
      participants.value = [];
    }
    isLoadingPage.value = false;
    isLoadingRoom.value = false;
    error.value = null;
  });

  // exported Plugin functions

  async function switchToRoom(name: string) {
    await waitForInitalSync();
    roomName.value = name;
  }

  function switchToThread(commentId: string, addThread: boolean = false) {
    threadId.value = commentId;
    threadComments.value = [];
    threadRootComment.value = getComment(commentId);
    if (!threads.value[commentId]) {
      threads.value[commentId] = [];
    }

    threadComments.value = threads.value[commentId];
    if (!addThread) {
      chatStore.addThread(currentRoom.value!.name, commentId);
    }
  }

  async function sendMessage(message: string) {
    matrixClient.sendEvent(currentRoom.value!.roomId, EventType.RoomMessage, {
      body: message,
      msgtype: MsgType.Text
    });
  }
  async function sendThreadMessage(message: string) {
    matrixClient.sendEvent(
      currentRoom.value!.roomId,
      threadId.value! as string,
      EventType.RoomMessage, // Cast to the correct type
      {
        body: message,
        msgtype: MsgType.Text
      }
    );
  }

  function updateMessage(commentId: string, content: string) {
    const eventContent = {
      body: "* " + content,
      msgtype: MsgType.Text,
      "m.new_content": {
        body: content,
        msgtype: MsgType.Text
      },
      "m.relates_to": {
        rel_type: RelationType.Replace,
        event_id: commentId
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    matrixClient.sendEvent(currentRoom.value!.roomId, EventType.RoomMessage, eventContent as any);
  }

  async function reactToComment(emoji: string, commentId: string) {
    //check if reaction allready exists, if so remove it first
    const comment = getComment(commentId);
    const reaction = comment.userReactions.find(
      (reaction) => reaction.emoji === emoji && reaction.user.username === matrixClient.getUserIdLocalpart()
    );

    if (reaction) {
      return await removeReaction(reaction.id);
    }

    try {
      const event = {
        "m.relates_to": {
          rel_type: "m.annotation" as sdk.RelationType.Annotation, // Explicitly set to Annotation
          event_id: commentId,
          key: emoji
        }
      };
      await matrixClient.sendEvent(currentRoom.value!.roomId, sdk.EventType.Reaction, event);
    } catch (error) {
      console.error(`Failed to react to comment ${commentId}`, error);
    }
  }

  async function removeReaction(reactionEventId: string) {
    try {
      await matrixClient.redactEvent(currentRoom.value!.roomId, reactionEventId);
    } catch (error) {
      console.error(`Failed to remove reaction with event ID ${reactionEventId}`, error);
    }
  }

  async function fetchNextPage() {
    if (!currentRoom.value) {
      $logger.warn("chat fetchNextPage: currentRoom not found");
      return;
    }

    isLoadingPage.value = true;
    try {
      canPaginate.value = await matrixClient.paginateEventTimeline(currentRoom.value!.getLiveTimeline(), {
        backwards: true,
        limit: EVENT_PAGE_SIZE
      });
    } catch (error) {
      //TODO: Error handling
      console.error("Failed to get next page", error);
    } finally {
      isLoadingPage.value = false;
    }
  }

  async function deleteMessage(eventId: string) {
    try {
      await matrixClient.redactEvent(currentRoom.value!.roomId, eventId, undefined, {
        reason: CHAT_REDACTED_REASONS.DELETED_BY_USER
      });
    } catch (error) {
      console.error(`Failed to delete message with event ID ${eventId}`, error);
    }
  }

  async function blockMessage(eventId: string) {
    try {
      await matrixClient.redactEvent(currentRoom.value!.roomId, eventId, undefined, {
        reason: CHAT_REDACTED_REASONS.BLOCKED_BY_MODERATOR
      });
      $logger.debug(`Message with event ID ${eventId} blocked successfully.`);
    } catch (error) {
      console.error(`Failed to block message with event ID ${eventId}`, error);
    }
  }

  function roomAliasToRoomName(alias: string) {
    return alias.split(":")[0].replaceAll("#", "");
  }

  return {
    provide: {
      chat: {
        currentRoom,
        matrixClient,
        nameToChannel,
        switchToRoom,
        isLoadingPage,
        isLoadingRoom,
        comments,
        participants,
        switchToThread,
        threadComments,
        threadRootComment,
        sendMessage,
        updateMessage,
        sendThreadMessage,
        reactToComment,
        removeReaction,
        fetchNextPage,
        canPaginate,
        deleteMessage,
        blockMessage
      }
    }
  };
});
