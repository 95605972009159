import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["getUserAccountView","getCategories","getCategoriesPaginated","deleteCategory","insertCategory","insertClaimCategory","deleteClaimCategory","getChannels","AskCheckbot","deleteClaimById","deleteOriginById","filterClaims","getClaimById","insertClaimOrigin","updateClaimInternal","updateOriginUrl","updateOriginArchiveUrl","updateOriginExcerpt","updateOriginRemarks","updateSourceFileIdToNull","updateClaimPublishedUrl","updateClaimRatingLabelName","updateClaimRatingStatement","updateClaimRatingSummary","updateClaimSubmitterNotes","updateClaimSynopsis","getLastEventId","subscribeEvents","deleteFactById","insertFact","updateFactTextById","GetHandbookSections","GetHandbookSectionById","UpdateHandbookSectionTeaser","UpdateHandbookSectionContent","InsertHandbookSection","DeleteHandbookSection","mutateBlockRoomMessage","GetPageContentByPageName","UpdatePageContent","getRatingLabels","getRatingLabelsPaginated","deleteRatingLabel","insertRatingLabel","insertSourceByFactId","deleteSourceById","updateSourceUrl","updateSourceArchiveUrl","updateSourceExcerpt","updateSourceRemarks","updateOriginFileIdToNull","filterSubmission","getSubmissionById","insertSubmissionOne","updateSubmissionStatus","getUserById","getUserByName","getUserRole","getUsers","getUsersPaginated","updateUserInfo","updateUserRole","updateUserSignedCodeOfConduct","insertUserClaimStatusOne","updateUserClaimStatusByPkHasRead","updateUserClaimStatusByPkHasMarked"]}
export const GqlAskCheckbot = (...params) => useGql()('AskCheckbot', ...params)
export const GqlDeleteHandbookSection = (...params) => useGql()('DeleteHandbookSection', ...params)
export const GqlGetHandbookSectionById = (...params) => useGql()('GetHandbookSectionById', ...params)
export const GqlGetHandbookSections = (...params) => useGql()('GetHandbookSections', ...params)
export const GqlGetPageContentByPageName = (...params) => useGql()('GetPageContentByPageName', ...params)
export const GqlInsertHandbookSection = (...params) => useGql()('InsertHandbookSection', ...params)
export const GqlUpdateHandbookSectionContent = (...params) => useGql()('UpdateHandbookSectionContent', ...params)
export const GqlUpdateHandbookSectionTeaser = (...params) => useGql()('UpdateHandbookSectionTeaser', ...params)
export const GqlUpdatePageContent = (...params) => useGql()('UpdatePageContent', ...params)
export const GqlDeleteCategory = (...params) => useGql()('deleteCategory', ...params)
export const GqlDeleteClaimById = (...params) => useGql()('deleteClaimById', ...params)
export const GqlDeleteClaimCategory = (...params) => useGql()('deleteClaimCategory', ...params)
export const GqlDeleteFactById = (...params) => useGql()('deleteFactById', ...params)
export const GqlDeleteOriginById = (...params) => useGql()('deleteOriginById', ...params)
export const GqlDeleteRatingLabel = (...params) => useGql()('deleteRatingLabel', ...params)
export const GqlDeleteSourceById = (...params) => useGql()('deleteSourceById', ...params)
export const GqlFilterClaims = (...params) => useGql()('filterClaims', ...params)
export const GqlFilterSubmission = (...params) => useGql()('filterSubmission', ...params)
export const GqlGetCategories = (...params) => useGql()('getCategories', ...params)
export const GqlGetCategoriesPaginated = (...params) => useGql()('getCategoriesPaginated', ...params)
export const GqlGetChannels = (...params) => useGql()('getChannels', ...params)
export const GqlGetClaimById = (...params) => useGql()('getClaimById', ...params)
export const GqlGetLastEventId = (...params) => useGql()('getLastEventId', ...params)
export const GqlGetRatingLabels = (...params) => useGql()('getRatingLabels', ...params)
export const GqlGetRatingLabelsPaginated = (...params) => useGql()('getRatingLabelsPaginated', ...params)
export const GqlGetSubmissionById = (...params) => useGql()('getSubmissionById', ...params)
export const GqlGetUserAccountView = (...params) => useGql()('getUserAccountView', ...params)
export const GqlGetUserById = (...params) => useGql()('getUserById', ...params)
export const GqlGetUserByName = (...params) => useGql()('getUserByName', ...params)
export const GqlGetUserRole = (...params) => useGql()('getUserRole', ...params)
export const GqlGetUsers = (...params) => useGql()('getUsers', ...params)
export const GqlGetUsersPaginated = (...params) => useGql()('getUsersPaginated', ...params)
export const GqlInsertCategory = (...params) => useGql()('insertCategory', ...params)
export const GqlInsertClaimCategory = (...params) => useGql()('insertClaimCategory', ...params)
export const GqlInsertClaimOrigin = (...params) => useGql()('insertClaimOrigin', ...params)
export const GqlInsertFact = (...params) => useGql()('insertFact', ...params)
export const GqlInsertRatingLabel = (...params) => useGql()('insertRatingLabel', ...params)
export const GqlInsertSourceByFactId = (...params) => useGql()('insertSourceByFactId', ...params)
export const GqlInsertSubmissionOne = (...params) => useGql()('insertSubmissionOne', ...params)
export const GqlInsertUserClaimStatusOne = (...params) => useGql()('insertUserClaimStatusOne', ...params)
export const GqlMutateBlockRoomMessage = (...params) => useGql()('mutateBlockRoomMessage', ...params)
export const GqlSubscribeEvents = (...params) => useGql()('subscribeEvents', ...params)
export const GqlUpdateClaimInternal = (...params) => useGql()('updateClaimInternal', ...params)
export const GqlUpdateClaimPublishedUrl = (...params) => useGql()('updateClaimPublishedUrl', ...params)
export const GqlUpdateClaimRatingLabelName = (...params) => useGql()('updateClaimRatingLabelName', ...params)
export const GqlUpdateClaimRatingStatement = (...params) => useGql()('updateClaimRatingStatement', ...params)
export const GqlUpdateClaimRatingSummary = (...params) => useGql()('updateClaimRatingSummary', ...params)
export const GqlUpdateClaimSubmitterNotes = (...params) => useGql()('updateClaimSubmitterNotes', ...params)
export const GqlUpdateClaimSynopsis = (...params) => useGql()('updateClaimSynopsis', ...params)
export const GqlUpdateFactTextById = (...params) => useGql()('updateFactTextById', ...params)
export const GqlUpdateOriginArchiveUrl = (...params) => useGql()('updateOriginArchiveUrl', ...params)
export const GqlUpdateOriginExcerpt = (...params) => useGql()('updateOriginExcerpt', ...params)
export const GqlUpdateOriginFileIdToNull = (...params) => useGql()('updateOriginFileIdToNull', ...params)
export const GqlUpdateOriginRemarks = (...params) => useGql()('updateOriginRemarks', ...params)
export const GqlUpdateOriginUrl = (...params) => useGql()('updateOriginUrl', ...params)
export const GqlUpdateSourceArchiveUrl = (...params) => useGql()('updateSourceArchiveUrl', ...params)
export const GqlUpdateSourceExcerpt = (...params) => useGql()('updateSourceExcerpt', ...params)
export const GqlUpdateSourceFileIdToNull = (...params) => useGql()('updateSourceFileIdToNull', ...params)
export const GqlUpdateSourceRemarks = (...params) => useGql()('updateSourceRemarks', ...params)
export const GqlUpdateSourceUrl = (...params) => useGql()('updateSourceUrl', ...params)
export const GqlUpdateSubmissionStatus = (...params) => useGql()('updateSubmissionStatus', ...params)
export const GqlUpdateUserClaimStatusByPkHasMarked = (...params) => useGql()('updateUserClaimStatusByPkHasMarked', ...params)
export const GqlUpdateUserClaimStatusByPkHasRead = (...params) => useGql()('updateUserClaimStatusByPkHasRead', ...params)
export const GqlUpdateUserInfo = (...params) => useGql()('updateUserInfo', ...params)
export const GqlUpdateUserRole = (...params) => useGql()('updateUserRole', ...params)
export const GqlUpdateUserSignedCodeOfConduct = (...params) => useGql()('updateUserSignedCodeOfConduct', ...params)