export function useSEO() {
  const { siteName } = useRuntimeConfig().public;
  const { origin } = useRequestURL();

  const description =
    "Ein Gerücht in der Messengergruppe, eine Parole am Stammtisch, ein gefälschtes Video in einem Sozialen Netzwerk – Desinformation hat viele Gesichter und begegnet uns an unterschiedlichen Orten. Im CORRECTIV.Faktenforum kannst du Falschbehauptungen zusammentragen und etwas gegen sie unternehmen. Ob mit oder ohne Vorkenntnisse – werde Teil der Community und checke gemeinsam mit uns Fakten.";
  const ogImage = origin + "/og-image.jpg";

  useSeoMeta({
    title: siteName,
    ogTitle: siteName,
    description,
    robots: "index,follow",
    twitterCard: "summary_large_image",
    twitterSite: "@site",
    twitterCreator: "@handle",
    ogDescription: description,
    ogImage,
    ogUrl: origin,
    ogType: "website",
    ogImageType: "image/png",
    ogImageWidth: 1200,
    ogImageHeight: 630,
    ogLocale: "de_DE",
    ogSiteName: siteName,
    msapplicationTileColor: "#000000",
    themeColor: "#000000",
    appleMobileWebAppTitle: siteName,
    mobileWebAppCapable: "yes",
    appleMobileWebAppStatusBarStyle: "black-translucent"
  });

  // https://realfavicongenerator.net

  useHead({
    link: [
      {
        rel: "canonical",
        href: origin
      },
      {
        rel: "manifest",
        href: `${origin}/manifest.json`
      },

      {
        rel: "icon",
        type: "image/png",
        sizes: "16x16",
        href: `${origin}/icons/favicon-16x16.png`
      },
      {
        rel: "icon",
        type: "image/png",
        sizes: "32x32",
        href: `${origin}/icons/favicon-32x32.png`
      },
      {
        rel: "apple-touch-icon",
        type: "image/png",
        sizes: "60x60",
        href: `${origin}/icons/apple-touch-icon-60x60.png`
      },
      {
        rel: "apple-touch-icon",
        type: "image/png",
        sizes: "76x76",
        href: `${origin}/icons/apple-touch-icon-76x76.png`
      },
      {
        rel: "apple-touch-icon",
        type: "image/png",
        sizes: "120x120",
        href: `${origin}/icons/apple-touch-icon-120x120.png`
      },
      {
        rel: "apple-touch-icon",
        type: "image/png",
        sizes: "152x152",
        href: `${origin}/icons/apple-touch-icon-152x152.png`
      },
      {
        rel: "apple-touch-icon",
        type: "image/png",
        sizes: "180x180",
        href: `${origin}/icons/apple-touch-icon-180x180.png`
      },
      {
        rel: "mask-icon",
        type: "image/png",
        href: `${origin}/icons/safari-pinned-tab.svg`,
        color: "#000000"
      },
      {
        rel: "apple-touch-startup-image",
        type: "image/png",
        href: `${origin}/icons/apple-touch-icon-start-up-320x480.png`
      }
    ]
  });
}
