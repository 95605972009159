<script setup lang="ts">
defineProps<{
  class?: string;
}>();
const { $ory } = useNuxtApp();
const { username } = useSessionInfo();
const hasAccess = useHasAccess();
const currentTrigger = ref("");
</script>

<template>
  <NavigationMenuRoot
    v-model="currentTrigger"
    :class="cn('relative z-[1] [&>div]:w-full flex justify-stretch', $props.class)"
    :delayDuration="0"
    :skipDelayDuration="0"
  >
    <NavigationMenuList class="w-full m-0 flex list-none rounded-lg">
      <NavigationMenuItem class="w-full" asChild>
        <NavigationMenuTrigger
          :class="cn('w-full flex items-center select-none gap-2 outline-none group bg-transparent')"
        >
          <Avatar :class="cn('size-8')" as="div" :username />
          <p class="max-sm:hidden grow text-left">{{ username }}</p>
        </NavigationMenuTrigger>
        <NavigationMenuContent
          :class="
            cn(
              'absolute left-0 bottom-[calc(100%+0.5em)] border w-[200px]',
              'bg-neutral-0 rounded-lg',
              'data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut'
            )
          "
        >
          <ul class="p-1">
            <li class="w-full">
              <NavigationMenuLink asChild>
                <Button
                  :icon="ICONS.user"
                  text="mainnav.userProfile"
                  class="w-full !border-none justify-start"
                  variant="white"
                  to="/profile"
                />
              </NavigationMenuLink>
            </li>
            <li v-if="hasAccess.administrator" class="w-full">
              <NavigationMenuLink asChild>
                <Button
                  :icon="ICONS.users"
                  text="user.administration"
                  class="w-full !border-none justify-start"
                  variant="white"
                  to="/admin/users"
                />
              </NavigationMenuLink>
            </li>
            <li v-if="hasAccess.administrator" class="w-full">
              <NavigationMenuLink asChild>
                <Button
                  :icon="ICONS.settings"
                  text="Admin Settings"
                  class="w-full !border-none justify-start"
                  variant="white"
                  to="/admin/settings"
                />
              </NavigationMenuLink>
            </li>
            <li class="w-full">
              <NavigationMenuLink asChild>
                <Button
                  :icon="ICONS.logout"
                  text="buttons.logout"
                  class="w-full !border-none justify-start"
                  variant="white"
                  @click="$ory.logout()"
                />
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
    </NavigationMenuList>
  </NavigationMenuRoot>
</template>
