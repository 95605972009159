import type {
  LoginFlow,
  RecoveryFlow,
  RegistrationFlow,
  VerificationFlow,
  SuccessfulNativeRegistration,
  ContinueWithVerificationUi,
  UiNode
} from "@ory/client";

export enum OryMessageId {
  authenticationRequired = 1010003,
  invalidCredentials = 4000006,
  propertyMissing = 4000002,
  recoveryMailSent = 1060003,
  settingsChangedSuccessfully = 1050001,
  verificationEmailSend = 1080003,
  verificationSuccessful = 1080002,
  verificationAlreadyCompleted = 4070002,
  verificationExpired = 4070005,
  verificationInvalid = 4070006
}

export enum OryMessageType {
  error = "error",
  success = "success"
}

export enum OryAttributeType {
  input = "input"
}

export enum OryFlowState {
  passedChallenge = "passed_challenge",
  emailSent = "email_sent"
}

type AnyUiFlow = LoginFlow | RegistrationFlow | VerificationFlow | RecoveryFlow;

export function getCsrfToken(flow: AnyUiFlow) {
  return getUiNodeValue(flow, "csrf_token");
}
export function getCode(flow: AnyUiFlow) {
  let code: string;
  try {
    code = getUiNodeValue(flow, "code");
  } catch {
    return undefined;
  }
  return /^\d+$/.test(code) ? code : undefined;
}
export function getEmail(flow: AnyUiFlow) {
  return getUiNodeValue(flow, "email");
}
export function getPassword(flow: AnyUiFlow) {
  return getUiNodeValue(flow, "password");
}
export function getMethod(flow: AnyUiFlow) {
  return getUiNodeValue(flow, "method");
}
export function getVerificationUiUrl(result: SuccessfulNativeRegistration): string {
  if (!result.continue_with) {
    throw new Error("No continue_with action found");
  }

  const actions = result.continue_with.filter<ContinueWithVerificationUi>(
    (a) => a.action === "show_verification_ui"
  );
  if (!actions.length) {
    throw new Error("No show_verification_ui action found");
  }

  const verificationUrl = actions[0].flow.url;
  if (verificationUrl === undefined) {
    throw new Error("No url found in show_verification_ui action");
  }

  return verificationUrl;
}

export function isVerificationExpired(flow: VerificationFlow): boolean {
  return (
    flow?.ui?.messages?.some(
      (m) => m.type === OryMessageType.error && m.id === OryMessageId.verificationExpired
    ) ?? false
  );
}

export function isVerificationInvalid(flow: VerificationFlow): boolean {
  return (
    flow.ui.messages?.some(
      (m) => m.type === OryMessageType.error && m.id === OryMessageId.verificationInvalid
    ) ?? false
  );
}

export function isVerificationEmailSend(flow: VerificationFlow): boolean {
  return flow?.ui?.messages?.some((m) => m.id === OryMessageId.verificationEmailSend) ?? false;
}

export function isVerificationCompleted(flow: VerificationFlow): boolean {
  return (
    flow?.ui?.messages?.some(
      (m) => m.type === OryMessageType.success && m.id === OryMessageId.verificationAlreadyCompleted
    ) ?? false
  );
}

export function getUiNodeValue(flow: AnyUiFlow, name: string) {
  for (const { attributes } of flow.ui.nodes) {
    if (attributes.node_type === OryAttributeType.input && attributes.name === name) {
      return attributes.value;
    }
  }
  throw new Error(`${name} not found in Flow UI nodes`);
}

export function existUiNode(flow: AnyUiFlow, name: string) {
  return flow?.ui?.nodes?.some(
    (n) => n.attributes.node_type === OryAttributeType.input && n.attributes.name === name
  );
}

export function getUiNodesMessages(flow: AnyUiFlow) {
  let messages: UiNode["messages"] = [];
  flow?.ui?.nodes.forEach((uinode) => {
    messages = uinode.messages.concat(messages);
  });
  return messages;
}

export function hasAccess(actualRole: UserRole, minRequiredRole: UserRole) {
  const currentUserPowerlevel = USER_ROLE_POWERLEVELS[actualRole];
  const minRequiredPowerlevel = USER_ROLE_POWERLEVELS[minRequiredRole];

  if (!currentUserPowerlevel || !minRequiredPowerlevel) {
    console.warn("Encountered an unknown user role, one of", actualRole, minRequiredRole);
    return false;
  }
  return currentUserPowerlevel >= minRequiredPowerlevel;
}
