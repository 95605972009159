import { type VariantProps, cva } from "class-variance-authority";

export const buttonVariants = cva(
  cn(
    "inline-flex gap-3 items-center justify-center whitespace-nowrap shrink-0 rounded-md transition-colors",
    "focus:outline-none disabled:pointer-events-none disabled:opacity-50"
  ),
  {
    variants: {
      variant: {
        default: cn(
          "bg-neutral-1 text-neutral-8 border-neutral-2 ",
          "hocus:bg-neutral-2 [&.has-group]:group-hocus:bg-neutral-2",
          "border focus:border-neutral-5 [&.has-group]:group-focus:border-neutral-5",
          "[&:not(.has-text)_*]:text-neutral-8",
          "[&>svg]:hocus:text-neutral-8 [&.has-group_*]:group-hocus:text-neutral-8"
        ),
        none: "",
        active: "bg-neutral-2 text-neutral-8 hocus:bg-neutral-3",
        highlight: "bg-neutral-6 hocus:bg-neutral-5 text-neutral-1",
        outline: "border hover:bg-accent hover:text-accent-foreground",
        white: cn(
          "bg-neutral-0 text-neutral-7",
          "hover:bg-neutral-1 [&.has-group]:group-hover:bg-neutral-1 border hocus:border-neutral-5 hocus:text-neutral-8",
          "[&>svg]:text-neutral-4 [&>svg]:hover:text-neutral-8 [&.has-group_*]:group-hover:text-neutral-8",
          "[&>svg]:data-[state=on]:text-orange-600 [&.has-group_*]:group-focus:text-neutral-8 [&>svg]:focus:text-neutral-8"
        ),
        link: "text-neutral-6 hocus:text-neutral-8 underline-offset-4 underline decoration-neutral-3 hocus:decoration-neutral-6 !px-0 !h-auto",
        markedActive: "bg-blue-300 hocus:bg-blue-400 dark:text-neutral-0",
        markedCommunity: "bg-neutral-3 hocus:bg-neutral-4 dark:text-neutral-0",
        correct: "bg-success text-neutral-0 border-none rounded-full",
        misleading: "bg-warning text-neutral-0 border-none rounded-full",
        false: "bg-error text-neutral-0 border-none rounded-full",
        toolbar:
          "bg-neutral-0 hover:bg-neutral-2 [&>svg]:text-neutral-6 [&:hover>svg]:text-neutral-8 [&:focus>svg]:text-neutral-8 ",
        deleteButton: cn(
          "hocus:bg-neutral-2 [&.has-group]:group-hocus:bg-neutral-2",
          "[&>svg]:text-neutral-4 [&>svg]:hocus:text-neutral-8"
        )
      },
      size: {
        xs: "h-5 min-w-5 rounded-md tracking-wide",
        sm: "h-8 min-w-8 rounded-md [&.has-text]:px-3 text-xs [&>svg]:size-5 tracking-wide",
        default: "h-10 min-w-10 [&.has-text]:px-4 text-base [&>svg]:size-5",
        lg: "h-11 min-w-11 rounded-md [&.has-text]:px-8 text-lg [&>svg]:size-6"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
