<script setup lang="ts">
import { gsap } from "gsap";

import { Icon } from "@iconify/vue";

import type { TToast } from "@/stores/toasts";

const icon = computed(() => {
  switch (props.type) {
    case "success":
      return "lucide-laugh";
    case "error":
      return "lucide-skull";
    case "warning":
      return "lucide-dog";
    case "info":
      return "lucide-wrench";
    default:
      return "lucide-wrench";
  }
});
const props = defineProps<TToast>();
const store = useToastsStore();
const el = ref<HTMLElement | null>(null);
const progressRef = ref<HTMLElement | null>(null);

const durationTimeline = gsap.timeline();

onMounted(() => {
  gsap.set(el.value, { opacity: 0, scale: 0.5, height: 0 });
  nextTick(() => {
    gsap.to(el.value, { opacity: 1, scale: 1, duration: 0.5, height: "auto" });
    durationTimeline.to(progressRef.value, {
      "--p": "100%",
      duration: props.duration ? props.duration / 1000 : 10,
      ease: "none"
    });
  });
});
function close() {
  gsap.to(el.value, {
    opacity: 0,
    height: 0,
    paddingTop: 0,
    scale: 0.5,
    duration: 0.5,
    ease: "power2.out",
    onComplete: () => {
      store.remove(props.id);
    }
  });
}
function onPause() {
  durationTimeline.pause();
}
function onResume() {
  durationTimeline.resume();
}

const sensitivity = computed(() => {
  if (props.sensitivity) return props.sensitivity;
  switch (props.type) {
    case "error":
      return "foreground";
    default:
      return "background";
  }
});
</script>

<template>
  <ToastRoot
    forceMount
    asChild
    :duration
    :type="sensitivity"
    @update:open="close"
    @pause="onPause"
    @resume="onResume"
  >
    <li ref="el" :class="cn('group relative shrink-0 w-full pb-3')">
      <div
        class="flex gap-3 py-3 pl-3 pr-8 rounded-lg bg-neutral-0 shadow-md border border-neutral-0 hover:border-neutral-5 focus:border-neutral-5 relative shrink-0"
      >
        <div
          ref="progressRef"
          style="--p: 0%"
          class="size-9 w-9 rounded-full bg-[conic-gradient(black_var(--p),_0,transparent)] flex items-center justify-center shrink-0"
        >
          <Icon
            :icon="icon"
            :class="
              cn('size-8 rounded-full p-1 bg-blue-200 shrink-0', {
                'bg-green-200': type === 'success',
                'bg-orange-400': type === 'warning',
                'bg-red-400': type === 'error'
              })
            "
          />
        </div>
        <div class="grow">
          <ToastTitle v-if="title" class="font-semibold">
            {{ title }}
          </ToastTitle>
          <ToastDescription v-if="description" class="text-neutral-5">
            {{ description }}
          </ToastDescription>
          <ToastClose class="absolute right-1 top-1">
            <Button variant="white" size="sm" :icon="ICONS.cancel" @click="close" />
          </ToastClose>
        </div>
      </div>
    </li>
  </ToastRoot>
</template>
