export function nonReactiveClone(object: unknown) {
  return JSON.parse(JSON.stringify(object));
}

export function fileToSizeString(bytes: number) {
  if (bytes < 1000) return `${bytes} bytes`;
  if (bytes < 1000000) return `${(bytes / 1000).toFixed(2)} kb`;
  if (bytes < 1000000000) return `${(bytes / 1000000).toFixed(2)} mb`;
  if (bytes < 1000000000000) return `${(bytes / 1000000000).toFixed(2)} gb`;
  return `${(bytes / 1000000000000).toFixed(2)} tb`;
}

export function fileToUrl(file: File) {
  return URL.createObjectURL(file);
}

export function isUUIDv4(str: string): boolean {
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(str);
}

export const DateTimeFormatString = "YYYY-MM-DD HH:mm:ss";

export function toRelativeUrl(url: string): string {
  const urlObject = new URL(url);
  return urlObject.pathname + urlObject.search;
}

type GenericObject = { [key: string]: unknown };

export function getFirstDefinedField<T extends GenericObject, K extends keyof T>(
  array: T[],
  field: K
): T[K] | undefined {
  const foundObject = array.find((item) => item[field] !== undefined);
  return foundObject ? foundObject[field] : undefined;
}
export { default as clsx } from "clsx";

export function findObjectIn2DimArray<Data>(
  array: Data[][],
  key: keyof Data,
  value: unknown
): [number, number] | undefined {
  for (let rowIndex = 0; rowIndex < array.length; rowIndex++) {
    const row = array[rowIndex];
    for (let colIndex = 0; colIndex < row.length; colIndex++) {
      const item = row[colIndex];
      if (item[key] === value) {
        return [rowIndex, colIndex];
      }
    }
  }
  return undefined;
}

export function toPlainClone(object: unknown) {
  if (!object) return undefined;
  return JSON.parse(JSON.stringify(object));
}

export function isEmptyObject(object: object) {
  return Object.keys(object).length === 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function chainFunctions(...funcs: (((...args: any[]) => any) | undefined)[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (...args: any[]) {
    funcs.forEach((func) => {
      if (func) func(...args);
    });
  };
}

/**
 * A utility function that retries a given async function with exponential backoff.
 *
 * @param fn - The async function to execute.
 * @param retries - How many times to retry before failing.
 * @param baseDelay - Delay in ms. The delay is multiplied by 2 for every subsequent attempt.
 */
export async function withRetry<T>(fn: () => Promise<T>, retries = 3, baseDelay = 1000): Promise<T> {
  let attempt = 1;
  while (attempt <= retries) {
    try {
      return await fn();
    } catch (error) {
      if (attempt === retries) {
        throw error;
      }
      // Exponential backoff

      const delay = baseDelay * 2 ** (attempt - 1);
      console.log("delay", delay);
      await new Promise((resolve) => setTimeout(resolve, delay));
      attempt++;
    }
  }
  // Should never reach here unless you set retries < 1
  throw new Error("No retries set. ‘withRetry’ exited prematurely.");
}
