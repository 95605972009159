<script setup lang="ts">
const store = useToastsStore();
</script>

<template>
  <ToastProvider>
    <Toast
      v-for="toast in store.toasts"
      :key="toast.id"
      v-bind="toast"
      :data-id="toast.id"
      :duration="10000"
    />
    <ToastViewport class="fixed z-[100] top-0 right-0 flex flex-col-reverse md:max-w-[420px] w-full p-4" />
  </ToastProvider>
</template>
