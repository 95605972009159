<script setup lang="ts">
import { Field } from "vee-validate";

const props = defineProps<{
  name: string;
  label?: string;
  class?: string;
  disabled?: boolean;
  type?: "text" | "textarea" | "email" | "password" | "number";
  autofocus?: boolean;
  autocomplete?: string;
}>();

const model = defineModel();

const inputRef = ref<HTMLInputElement | null>(null);

function setTextareaHeight() {
  if (inputRef.value && props.type === "textarea") {
    inputRef.value.style.height = inputRef.value.scrollHeight + "px";
  }
}

onMounted(() => {
  if (props.autofocus) {
    inputRef.value?.focus();
  }
  setTextareaHeight();
});

const classesTouched = cn(
  "top-[-0.82rem] left-3.5 text-orange-600 origin-left scale-[0.82]",
  "after:absolute after:bg-neutral-0 after:inset-x-0 after:top-3 after:bottom-0 after:-z-10"
);

watch(model, () => {
  if (!inputRef.value || props.type !== "textarea") return;
  inputRef.value.style.height = "auto";
  nextTick(setTextareaHeight);
});
</script>

<template>
  <Field
    v-slot="{ field: fieldBinding, errorMessage, meta, setTouched }"
    v-model="model"
    :name="name"
    :validate-on-blur="!!model"
    class="relative group"
    as="div"
  >
    {{ setTouched(!!model) }}
    <label
      v-if="label"
      :class="
        cn(
          errorMessage && 'text-red-500',
          'absolute top-3.5 left-4 text-lg text-neutral-5 px-1',
          'transition-all origin-left pointer-events-none h-auto',
          {
            [classesTouched]: meta.dirty || meta.touched
          }
        )
      "
      :for="name"
    >
      {{ $t(label) }}
    </label>
    <textarea
      v-if="type === 'textarea'"
      :id="name"
      ref="inputRef"
      :autocomplete
      v-bind="fieldBinding"
      :disabled
      :class="
        cn(
          'bg-neutral-0 flex w-full rounded-md border p-4 text-lg',
          'placeholder:text-muted-foreground focus-visible:outline-none focus-visible:border-orange-600',
          'disabled:cursor-not-allowed disabled:opacity-50',
          $props.class
        )
      "
      @animationstart="
        () => {
          setTouched(true);
        }
      "
    />
    <input
      v-else
      :id="name"
      ref="inputRef"
      :autocomplete
      v-bind="fieldBinding"
      :disabled
      :type="type || 'text'"
      :class="
        cn(
          'bg-neutral-0 flex h-14 w-full rounded-md border px-4 text-lg',
          'placeholder:text-muted-foreground focus-visible:outline-none focus-visible:border-orange-600',
          'disabled:cursor-not-allowed disabled:opacity-50',
          $props.class
        )
      "
      @animationstart="
        () => {
          setTouched(true);
        }
      "
    />
    <p v-if="errorMessage" name="email" class="text-sm font-medium text-red-500 my-2">{{ errorMessage }}</p>
  </Field>
</template>
