<script lang="ts" setup>
import { Icon } from "@iconify/vue";

import type { NavigationLink } from "~/types";

const props = defineProps<{
  link: NavigationLink;
  level?: number;
  isMobile?: boolean;
}>();

const route = useRoute();

const isActive = computed(() => isLinkActive(route.path, props.link?.to));
const hasAccess = useHasAccess();
</script>

<template>
  <li
    v-if="link.minPowerLevel ? hasAccess[link.minPowerLevel] : true"
    :class="
      cn('grid gap-1', {
        'group/l1': level === 1,
        'group/l2': level === 2,
        'pointer-events-none opacity-50': link.disabled,
        'relative before:absolute before:w-px before:top-0 before:left-4 before:bottom-4 before:bg-neutral-2':
          level === 1 && (isActive || isMobile),
        'before:!bottom-[0.55rem]': level === 1 && (isActive || isMobile) && !link.sublinks?.[0]?.icon,
        'relative before:absolute before:h-px before:w-3 before:top-4 before:right-full before:bg-neutral-2':
          level === 2,
        'before:-left-[11px] before:w-[28px] before:!top-2.5': level === 2 && !link?.icon
      })
    "
  >
    <NuxtLink
      :to="link.to"
      :class="
        cn('flex items-center gap-3 w-full justify-start text-neutral-6', {
          'font-semibold text-neutral-8': isActive,
          'pl-6 text-sm': !link.icon
        })
      "
    >
      <Icon
        v-if="link.icon"
        :icon="link.icon"
        :class="
          cn('size-8 p-1.5 rounded-md relative z-10 transition', 'border bg-neutral-0 text-neutral-5', {
            'group-hover/l1:text-neutral-8 group-hover/l1:border-neutral-3': level === 1,
            'group-hover/l2:text-neutral-8 group-hover/l2:border-neutral-3': level === 2,
            'text-neutral-8 !border-neutral-5': isActive
          })
        "
      />
      <span
        :class="
          cn({
            'group-hocus/l1:text-neutral-8': level === 1,
            'group-hocus/l2:text-neutral-8': level === 2
          })
        "
      >
        {{ link.titleDynamic ? link.title : $t(link.title) }}
      </span>
      <span v-if="link.label" :class="cn('ml-auto text-background')">
        {{ link.label }}
      </span>
    </NuxtLink>
    <ul v-if="(isActive || isMobile) && link.sublinks && !link.hideSublinksOnMobile" class="pl-7 grid gap-1">
      <NavElement v-for="sublink in link.sublinks" :key="sublink.to" :link="sublink" :level="2" />
    </ul>
  </li>
</template>
