import { defineStore } from "pinia";

export type ChatUiState = {
  scrollTop: number;
};

export const useChatStore = defineStore("chat", {
  state: () => ({
    chats: {} as { [id: string]: ChatUiState },
    threads: {} as Record<string, string | undefined>
  }),
  actions: {
    addThread(channelId: string, commentId: string) {
      this.threads = {
        ...this.threads,
        [channelId]: commentId
      };
      const userProfileStore = useUserProfileStore();
      userProfileStore.username = "";
      // this.collapseNavigation(true);
    },
    removeThread(channelId?: string) {
      if (!channelId || !this.threads[channelId]) return;
      this.threads[channelId] = undefined;
      // this.collapseNavigation(false);
    },
    setChatScrollTop(channelId: string, scrollTop: number) {
      if (!this.chats[channelId]) {
        this.chats[channelId] = { scrollTop };
      } else {
        this.chats[channelId].scrollTop = scrollTop;
      }
    }

    // collapseNavigation(collapse: boolean) {
    //   const navigationStore = useNavigationStore();
    //   navigationStore.setCollapsed(collapse);
    // }
  },
  getters: {
    getThreadIdByChannelId: (state) => (channelId: string) => {
      return state.threads[channelId];
    },
    getChatScrollTop: (state) => (channelId: string) => {
      return state.chats[channelId]?.scrollTop;
    }
  }
});
