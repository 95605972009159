import { defineStore } from "pinia";

export const useFilteringStore = defineStore("filtering", {
  state: () => ({
    userClaimState: [] as ("isMarked" | "isNew")[],
    categories: [] as string[],
    scope: "all" as "all" | "editorial" | "community"
  }),
  actions: {
    reset() {
      this.userClaimState = [];
      this.categories = [];
      this.scope = "all";
    }
  },
  getters: {
    getIsMarked: (state) => () => state.userClaimState.includes("isMarked"),
    getIsNew: (state) => () => state.userClaimState.includes("isNew")
  }
  // persist: {
  //   storage: persistedState.sessionStorage
  // }
});
