<script lang="ts" setup>
import NavElement from "./NavElement.vue";
import type { NavigationLink } from "~/types";

defineProps<{
  class?: string;
}>();

const store = useNavigationStore();
const links = useNavigationLinks();
</script>

<template>
  <ul
    :class="
      cn(
        'w-full flex flex-col gap-1 px-5',
        {
          'w-max px-2.5': store.collapsed
        },
        $props.class
      )
    "
  >
    <NavElement v-for="link in links" :key="link.to" :link="link as NavigationLink" :level="1" is-mobile />
  </ul>
</template>
